import type { Coupon, Price, User } from '../types/entities'
import type { ObjectDetection } from '../../../../../lib/database/lib/types'
import { EnumImageGenerationStatus, EnumMembershipType } from '../../../../../lib/database/lib/generated/enums'

export const getDiscount = (price: Price, coupon?: Coupon | null): number => {
  let discount = 0

  if (coupon?.amount_off) {
    discount = Number((coupon.amount_off / 100).toFixed(2))
  } else if (coupon?.percent_off) {
    discount = Number(((price.price * (+coupon.percent_off / 100)) / 100).toFixed(2))
  }

  return discount
}

export const getDiscountedPrice = (price: Price, coupon?: Coupon | null): number => {
  return coupon ? price.price / 100 - getDiscount(price, coupon) : price.price / 100
}

export const humanReadableBytes = (bytes: number, siUnits = false, decimals = 1): string => {
  const thresh = siUnits ? 1000 : 1024

  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`
  }

  const units = siUnits ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  const r = 10 ** decimals

  do {
    bytes /= thresh
    u += 1
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

  return `${bytes.toFixed(decimals)} ${units[u]}`
}

export const getRandomArbitrary = (min: number, max: number): number => {
  return Math.random() * (max - min) + min
}

export const getRandomInt = (min: number, max: number): number => {
  min = Math.ceil(min)
  max = Math.floor(max)

  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const objectDetectionToObject = (objectDetectionStr: string, width: number, height: number): ObjectDetection => {
  const result: ObjectDetection = {}

  const regex = /([^<]+)(?:<loc_\d+><loc_\d+><loc_\d+><loc_\d+>)+/g

  const matches = objectDetectionStr.matchAll(regex)

  for (const match of matches) {
    const rawLocs = [...match[0].matchAll(/loc_(\d+)/g)]

    const key = match[1]?.toLowerCase() ?? 'unknown'

    result[key] = []

    const locs = []

    for (const rawLoc of rawLocs) {
      locs.push(rawLoc[1])
    }

    for (let i = 0; i <= locs.length - 4; i += 4) {
      // Everything is normalized to 0-998 coming out of the model, where a value of 998 would mean full width or height
      result[key]?.push({
        x1: width * (+(locs[i] ?? 0) / 998),
        y1: height * (+(locs[i + 1] ?? 0) / 998),
        x2: width * (+(locs[i + 2] ?? 0) / 998),
        y2: height * (+(locs[i + 3] ?? 0) / 998),
      })
    }
  }

  return result
}

export const getLongFullName = (firstName?: string | null, middleName?: string | null, lastName?: string | null): string => {
  const nameParts = []

  if (firstName) {
    nameParts.push(firstName)
  }

  if (middleName) {
    nameParts.push(middleName)
  }

  if (lastName) {
    nameParts.push(lastName)
  }

  return nameParts.length ? nameParts.join(' ') : ''
}

export const getUserLongFullName = (user: User): string => {
  return getLongFullName(user.first_name, user.middle_name, user.last_name)
}

export const getShortFullName = (firstName?: string | null, lastName?: string | null): string => {
  const nameParts = []

  if (firstName) {
    nameParts.push(firstName)
  }

  if (lastName) {
    nameParts.push(lastName)
  }

  return nameParts.length ? nameParts.join(' ') : ''
}

export const getUserShortFullName = (user: User): string => {
  return getShortFullName(user.first_name, user.last_name)
}

export const hasMembershipOrHigher = (
  minMembershipType: keyof typeof EnumMembershipType,
  membershipType?: keyof typeof EnumMembershipType,
): boolean => {
  if (!membershipType) {
    return false
  }

  const membershipTypes = [EnumMembershipType.LITE, EnumMembershipType.PRO, EnumMembershipType.PREMIUM, EnumMembershipType.ENTERPRISE]

  const minMembershipIndex = membershipTypes.findIndex((item) => item === minMembershipType)
  const membershipIndex = membershipTypes.findIndex((item) => item === membershipType)

  return membershipIndex >= minMembershipIndex
}

export const sleep = async (millis: number): Promise<void> => {
  await new Promise((resolve) => {
    setTimeout(resolve, millis)
  })
}

export const setControllableTimeout = (
  callback: () => void,
  delay: number,
): {
  id: ReturnType<typeof setTimeout>
  hasRun: () => boolean
  clear: () => void
  restart: (newDelay: number) => void
  trigger: () => void
} => {
  let hasRun = false

  const handler = (): void => {
    hasRun = true
    callback()
  }

  let id = setTimeout(handler, delay)

  const clear = (): void => {
    clearTimeout(id)
  }

  return {
    id,
    hasRun: () => hasRun,
    clear,
    restart: (newDelay: number) => {
      clear()
      id = setTimeout(handler, newDelay || delay)
    },
    trigger: () => {
      clear()
      handler()
    },
  }
}

export const isActiveGeneratingStatus = (status?: keyof typeof EnumImageGenerationStatus | null): boolean => {
  return (
    [
      EnumImageGenerationStatus.INITIALIZING,
      EnumImageGenerationStatus.IN_QUEUE,
      EnumImageGenerationStatus.IN_PROGRESS,
      EnumImageGenerationStatus.CONVERTING_TO_SVG,
    ] as string[]
  ).includes(status ?? '')
}
