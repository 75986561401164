'use client'

import * as React from 'react'
import Link from 'next/link'
import { DateTime } from 'luxon'

export default function CookieConsent(): React.ReactElement {
  const [showCookieDialog, setShowCookieDialog] = React.useState<boolean>(false)

  React.useEffect(() => {
    const localStorageCookieConsentAccepted = localStorage.getItem('cookieConsentAccepted')

    if (localStorageCookieConsentAccepted === null) {
      setShowCookieDialog(true)
    }
  }, [])

  const acceptCookies = (): void => {
    localStorage.setItem('cookieConsentAccepted', 'true')
    localStorage.setItem('cookieConsentExpiration', DateTime.now().plus({ year: 1 }).toISO())

    setShowCookieDialog(false)
  }

  const rejectCookies = (): void => {
    localStorage.setItem('cookieConsentAccepted', 'false')
    localStorage.setItem('cookieConsentExpiration', DateTime.now().plus({ day: 1 }).toISO())

    setShowCookieDialog(false)
  }

  React.useEffect(() => {
    const expiration = localStorage.getItem('cookieConsentExpiration')

    if (!expiration) {
      return
    }

    if (DateTime.now() >= DateTime.fromISO(expiration)) {
      setShowCookieDialog(true)

      localStorage.removeItem('cookieConsentAccepted')
      localStorage.removeItem('cookieConsentExpiration')
    }
  }, [])

  return (
    <div>
      {showCookieDialog && (
        <div className="border-border dark:border-border-dark shadow-shadow dark:shadow-shadow-dark fixed inset-x-4 bottom-6 max-w-sm rounded-xl border bg-white p-6 pb-4 shadow-lg sm:inset-x-auto sm:right-6 sm:mx-auto sm:max-w-lg dark:bg-neutral-800">
          <h3 className="mb-4 text-xl font-semibold">We use cookies</h3>
          <div className="mb-4">
            <p className="mb-2 text-sm">
              This website uses cookies to ensure you get the best experience on our website. By continuing to use this site, you agree to
              our use of cookies as described in our{' '}
              <Link
                href="/cookie-policy"
                className="text-link-text hover:text-link-text-hover dark:text-link-text-dark dark:hover:text-link-text-dark-hover"
              >
                Cookie Policy
              </Link>
              .
            </p>
            <p className="text-sm">You can change your cookie settings at any time by adjusting your browser settings.</p>
          </div>
          <div className="border-border dark:border-border-dark flex justify-end gap-2 border-t pt-4">
            <button type="button" className="button button-primary inline-flex" onClick={acceptCookies}>
              Accept all
            </button>
            <button type="button" className="button button-default inline-flex" onClick={rejectCookies}>
              Reject all
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
